import * as React from "react";
import { Link } from "gatsby";
import * as styles from "./Blog.module.css";

const Blog = ({ posts }) => {
  return (
    <div className={styles.blogContainer}>
      {posts.map((post) => {
        const title = post.frontmatter.title || post.fields.slug;
        const blogImage = post.frontmatter.featuredImage
          ? post.frontmatter.featuredImage.childImageSharp.fluid.src
          : undefined;
        const path = `/${post.frontmatter.path}/`;

        return (
          <div key={path}>
            <article itemScope itemType="http://schema.org/Article">
              <Link to={path} itemProp="url" className={styles.cardContainer}>
                <header className={styles.blogCardHeaderContainer}>
                  {/*{blogImage && (*/}
                  {/*  <img src={blogImage} className={styles.blogImage} />*/}
                  {/*)}*/}
                  <small>{post.frontmatter.date}</small>
                  <h4 className={styles.blogTitle}>
                    <span itemProp="headline">{title}</span>
                  </h4>
                </header>
                {/* <section>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.description || post.excerpt,
                    }}
                    itemProp="description"
                  />
                </section> */}
              </Link>
            </article>
          </div>
        );
      })}
    </div>
  );
};

export default Blog;
