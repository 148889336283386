import * as React from "react";
import { graphql } from "gatsby";

import Blog from "../components/Blog";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import * as styles from "./index.module.css";

const Travel = ({ data, location }) => {
  //   const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes;

  return (
    <Layout location={location}>
      <Seo title="Blog" />
      <div className={styles.outerContainer}>
        <div>
          <Blog posts={posts} />
        </div>
      </div>
    </Layout>
  );
};

export default Travel;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        id
        frontmatter {
          categories
          date(formatString: "MMMM DD, YYYY")
          description
          title
          path
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
